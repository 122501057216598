
export enum ChargeState {
    NO_STATE                = 0xff,
    NOT_CHARGING            = 0x00,
    PRE_CHARGING            = 0x10,
    FAST_CHARGING           = 0x20,
    CHARGE_TERMINATION_DONE = 0x30
}

export interface InstrumentState {

    batteryPercent: number;
    
    usbPowered: boolean;

    bleConnected: boolean;

    uncEnabled: boolean;

    glitchReducerEnabled: boolean;

    glitchLearnEnabled: boolean;

    overblowEnabled: boolean;

    playWithoutBlowingEnabled: boolean;

    outputKSRSysexEnabled: boolean;

    outputKeyStrokeSysexEnabled: boolean;

    outputConditionalKeyStrokeSysexEnabled: boolean;

    maximumUNCRecords: number;

    maximumKSRRecords: number;

    uncBank: number;

    chargeState: ChargeState;

    advancedMode: boolean;

    bm20FirmwareMode: boolean;
}
